<template>
  <a-modal
    :title="form.id ? '编辑收货地址' : '添加收货地址'"
    :visible="visible"
    :width="500"
    :confirm-loading="confirmLoading"
    :destroyOnClose="true"
    :afterClose="afterClose"
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <div style="position: relative; margin-bottom: 30px">
      <div class="demo">
        示例：集小采，150××××0000，山东省济南市历下区某小区
      </div>
      <a-textarea
        v-model.trim="addressDesc"
        allowClear
        placeholder="请粘贴或输入文本，点击“识别”自动识别姓名、电话和地址"
        :rows="5"
        :autosize="{ minRows: 5, maxRows: 5 }"
        style="margin-top: 30px"
      />
      <!-- 张三 17822223333 山东省济南市历下区齐鲁国际大厦 -->
      <a-button
        type="primary"
        size="small"
        :disabled="!addressDesc"
        style="position: absolute; right: 10px; bottom: 10px"
        @click="onDiscern"
      >
        识别
      </a-button>
    </div>

    <a-form-model
      ref="ruleForm"
      :model="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-model-item
        label="收件人"
        prop="real_name"
        :rules="[
          { required: true, message: '请填写收件人姓名', trigger: 'blur' },
        ]"
      >
        <a-input
          v-model.trim="form.real_name"
          allowClear
          placeholder="请填写收件人姓名"
        />
      </a-form-model-item>
      <a-form-model-item
        label="联系方式"
        prop="phone"
        :rules="[
          { required: true, message: '请填写收件人联系方式', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请填写正确联系方式',
            trigger: 'blur',
          },
        ]"
      >
        <a-input
          v-model.trim="form.phone"
          :max-length="11"
          allowClear
          placeholder="请填写收件人联系方式"
        />
      </a-form-model-item>
      <a-form-model-item
        label="所在地区"
        prop="province_id"
        :rules="[
          { required: true, message: '请选择所在地区', trigger: 'blur' },
        ]"
      >
        <a-cascader
          :field-names="{ label: 'n', value: 'v', children: 'c' }"
          :options="areaArray"
          :value="
            form.province_id
              ? [form.province_id, form.city_id, form.district_id]
              : []
          "
          placeholder="请选择省市区"
          @change="onAreaChange"
        >
          <template
            slot="displayRender"
            slot-scope="{ labels, selectedOptions }"
          >
            <span
              v-for="(label, index) in labels"
              :key="selectedOptions[index].value"
            >
              <span> {{ label }} / </span>
            </span>
          </template>
        </a-cascader>
      </a-form-model-item>
      <a-form-model-item
        label="详细地址"
        prop="detail"
        :rules="[
          { required: true, message: '请填写详细地址', trigger: 'blur' },
        ]"
      >
        <a-input
          v-model.trim="form.detail"
          allowClear
          placeholder="请填写详细地址"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  addAddress,
  cityList,
  recognitionAddress,
} from "@/api/receivingAdress.js";

export default {
  name: "EditAddress",
  data() {
    return {
      visible: false,
      addressDesc: "",
      confirmLoading: false,
      form: {},
      areaArray: [],
    };
  },
  mounted() {
    this.loadAreas();
  },
  methods: {
    show(value = {}) {
      this.form = { ...value };
      this.visible = true;
    },
    onDiscern() {
      recognitionAddress({ address: this.addressDesc }).then((res) => {
        if (res.data.status == 200) {
          const val = res.data.data;
          this.$set(this.form, "real_name", val.real_name);
          this.$set(this.form, "phone", val.user_phone);
          this.$set(this.form, "province", val.province_name);
          this.$set(this.form, "province_id", val.province_id);
          this.$set(this.form, "city", val.city_name);
          this.$set(this.form, "city_id", val.city_id);
          this.$set(this.form, "district", val.district_name);
          this.$set(this.form, "district_id", val.district_id);
          this.$set(this.form, "detail", val.detail);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    loadAreas() {
      cityList().then((res) => {
        if (res.data.status == 200) {
          this.areaArray = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    onAreaChange(value, selectedOptio) {
      this.$set(this.form, "province", selectedOptio[0].n);
      this.$set(this.form, "province_id", selectedOptio[0].v);
      this.$set(this.form, "city", selectedOptio[1].n);
      this.$set(this.form, "city_id", selectedOptio[1].v);
      this.$set(this.form, "district", selectedOptio[2].n);
      this.$set(this.form, "district_id", selectedOptio[2].v);
    },
    onCancel() {
      this.visible = false;
    },
    afterClose() {
      this.form = {};
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          addAddress(this.form)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(this.form.id ? "修改成功" : "添加成功");
                this.$emit("complete");
                this.onCancel();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.demo {
  position: absolute;
  width: 100%;
  height: 35px;
  background-color: rgb(58, 184, 135);
  color: white;
  line-height: 30px;
  font-size: 13px;
  padding: 0 12px;
  border-radius: 4px 4px 0px 0px;
}
</style>
