import { getRequest, postRequest } from "@/utils/request";

//地址列表
export const addressList = (params) => {
  return getRequest("address/list", params);
};
//获取默认地址
export const getDefault = (params) => {
  return getRequest("pc/address/default", params);
};
// 添加/编辑
export const addAddress = (params) => {
  return postRequest("address/edit", params);
};
// 删除地址
export const delAddress = (params) => {
  return postRequest("address/del", params);
};
// 获取城市数据
export const cityList = (params) => {
  return getRequest("pc/city_list", params);
};

//地址识别
export const recognitionAddress = (params) => {
  return postRequest("order/recognitionAddress", params);
};
