var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.form.id ? '编辑收货地址' : '添加收货地址',"visible":_vm.visible,"width":500,"confirm-loading":_vm.confirmLoading,"destroyOnClose":true,"afterClose":_vm.afterClose},on:{"ok":_vm.onSubmit,"cancel":_vm.onCancel}},[_c('div',{staticStyle:{"position":"relative","margin-bottom":"30px"}},[_c('div',{staticClass:"demo"},[_vm._v(" 示例：集小采，150××××0000，山东省济南市历下区某小区 ")]),_c('a-textarea',{staticStyle:{"margin-top":"30px"},attrs:{"allowClear":"","placeholder":"请粘贴或输入文本，点击“识别”自动识别姓名、电话和地址","rows":5,"autosize":{ minRows: 5, maxRows: 5 }},model:{value:(_vm.addressDesc),callback:function ($$v) {_vm.addressDesc=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addressDesc"}}),_c('a-button',{staticStyle:{"position":"absolute","right":"10px","bottom":"10px"},attrs:{"type":"primary","size":"small","disabled":!_vm.addressDesc},on:{"click":_vm.onDiscern}},[_vm._v(" 识别 ")])],1),_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-form-model-item',{attrs:{"label":"收件人","prop":"real_name","rules":[
        { required: true, message: '请填写收件人姓名', trigger: 'blur' },
      ]}},[_c('a-input',{attrs:{"allowClear":"","placeholder":"请填写收件人姓名"},model:{value:(_vm.form.real_name),callback:function ($$v) {_vm.$set(_vm.form, "real_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.real_name"}})],1),_c('a-form-model-item',{attrs:{"label":"联系方式","prop":"phone","rules":[
        { required: true, message: '请填写收件人联系方式', trigger: 'blur' },
        {
          pattern: /^1[3456789]\d{9}$/,
          message: '请填写正确联系方式',
          trigger: 'blur',
        },
      ]}},[_c('a-input',{attrs:{"max-length":11,"allowClear":"","placeholder":"请填写收件人联系方式"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.phone"}})],1),_c('a-form-model-item',{attrs:{"label":"所在地区","prop":"province_id","rules":[
        { required: true, message: '请选择所在地区', trigger: 'blur' },
      ]}},[_c('a-cascader',{attrs:{"field-names":{ label: 'n', value: 'v', children: 'c' },"options":_vm.areaArray,"value":_vm.form.province_id
            ? [_vm.form.province_id, _vm.form.city_id, _vm.form.district_id]
            : [],"placeholder":"请选择省市区"},on:{"change":_vm.onAreaChange},scopedSlots:_vm._u([{key:"displayRender",fn:function({ labels, selectedOptions }){return _vm._l((labels),function(label,index){return _c('span',{key:selectedOptions[index].value},[_c('span',[_vm._v(" "+_vm._s(label)+" / ")])])})}}])})],1),_c('a-form-model-item',{attrs:{"label":"详细地址","prop":"detail","rules":[
        { required: true, message: '请填写详细地址', trigger: 'blur' },
      ]}},[_c('a-input',{attrs:{"allowClear":"","placeholder":"请填写详细地址"},model:{value:(_vm.form.detail),callback:function ($$v) {_vm.$set(_vm.form, "detail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.detail"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }